const es = {
    msgAreNew: "¿Nuevo en Metotrans?",
    msgPrivacy: "Privacidad y términos",
    msgContact: "Contáctanos",
    msgCopy: "&copy;2025 METOTRANS S.L. Todos los derechos reservados",

    msgAccountEntity: "Crear cuenta",
    msgWelcome: "Bienvenido",
    msgWelcomeSub: "Inicie sesión para acceder al servicio",

    msgSubscription1: "Suscripción ORO",
    msgSubscription2: "Suscripción PLATA",
    msgSubscription3: "Suscripción BRONCE",

    msgUserData: "Datos de usuario",
    msgEntityData: "Datos de organización",

    msgEmail: "Email",
    msgPassword: "Contraseña",
    msgFullName: "Nombre y apellidos",
    msgPhone: "Teléfono",

    msgPhoneCode: "Código país y Teléfono (+34--)",
    msgMatricula: "Matrícula (opcional)",
    msgDNI: "DNI/NIE/Pasaporte",

    msgSubscriptionType: "Suscripción",
    msgNameEntity: "Nombre de la empresa",
    msgRazonEntity: "Razón social",
    msgCIF: "CIF",
    msgAddress: "Dirección",
    msgPostalCode: "Código postal",
    msgHistoryDays: "Días histórico",
    msgCity: "Ciudad",
    msgProvince: "Provincia",
    msgCountry: "País",
    msgContactEntity: "Contacto",

    msgPaymentMethod: "Método de pago",
    msgCardNumber: "Número de tarjeta",
    msgMonth: "Mes",
    msgYear: "Año",
    msgCVC: "CVC",
    msgTitular: "Titular",

    msgExpirationDate: "Fecha de caducidad",

    msgUsers: "Usuarios",
    msgUsersText: "Listado de usuarios disponibles",
    msgNewUser: "Nuevo usuario",
    msgNewUserText: "Añade usuarios a tu cuenta",

    msgInbox: "Buzón de usuario",
    msgInboxText: "Envía archivos o enlaces al usuario", 
    msgCustomName: "Nombre personalizado",
    msgLink: "Enlace",
    msgAttachment: "Archivo adjunto",

    msgUserRefuel: "Repostajes",
    msgUserRefuelText: "Listado de repostajes del usuario",
    msgLitres: "Litros",
    msgKm: "Km's",
    msgDate: "Fecha",
    msgMatRefuel: "Matrícula",


    msgUser: "Usuario",
    msgEditUser: "Editar usuario",
    msgEditUserText: "Modifica los datos del usuario",

    msgNotes: "Notas de carga",
    msgNotesText: "Listado de notas disponibles",
    msgNewNote: "Nota de carga",
    msgNewNoteText: "Añade/edita notas de carga en tu cuenta",

    msgNote: "Nota de carga",
    msgEditNote: "Editar nota de carga",
    msgEditNoteText: "Modifica la nota de carga",

    msgOrders: "Partes de operación",
    msgOrdersText: "Listado de partes asociados a la nota de carga",
    msgOrdersListText: "Listado de partes de operaciones",

    msgOrder: "Parte de operación",
    msgNewOrderText: "Añade/edita partes de operaciones en tu cuenta",

    msgPoints: "Puntos",
    msgPointsText: "Listado de puntos asociados al parte de operaciones",

    msgPoint: "Punto",
    msgNewPointText: "Añade/edita puntos en tu cuenta",

    msgPointUserInfo: "Información",
    msgPointUserInfoText: "Aportada por el conductor",

    msgFiles: "Archivos",
    msgFilesText: "Listado de archivos asociados",

    msgLocations: "Localizaciones",
    msgLocationsText: "Listado de localizaciones asociados",

    msgAreSure: "¿Estas seguro?",

    msgAll: "Todos",
    msgPending: "Pendientes",
    msgInProgress: "En curso",
    msgEndeds: "Finalizados",
    msgNotAssign: "No asignado",
    msgAssign: "Asignado",
    msgAccepted: "Aceptado",
    msgRejected: "Rechazado",
    msgEnded: "Finalizado",

    msgIdentifier: "Identificador",
    msgNumPedido: "Nº pedido",
    msgNumNota: "Nº nota",
    msgCustomer: "Cliente",
    msgBussiness: "Empresa",
    msgDateStart: "Fecha inicio",
    msgDateEnd: "Fecha fin",
    msgLocationStart: "Origen",
    msgLocationEnd: "Destino",
    msgTemperature: "Temperatura (ºC)",
    msgNumPalets: "Nº palets",
    msgNumPaletsEnt: "Nº palets entregados",
    msgNumPaletsRec: "Nº palets recogidos",
    msgNumCMR: "Nº CMR",
    msgLatitude: "Latitud",
    msgLongitude: "Longitud",

    msgExchange: "Intercambio",
    msgObservations: "Observaciones",
    msgIncidence: "Incidencia",
    msgPreviousDate: "Cita previa",
    msgMat1: "Matrícula 1",
    msgMat2: "Matrícula 2",
    msgState: "Estado",
    msgType: "Tipo",
    msgDriver: "Conductor",
    msgDriverText: "Asignar/desasignar conductor a parte de operaciones",
    msgCommodity: "Mercancia",

    msgProfile: "Perfil",
    msgUserDataText: "Modifica los datos asociados a tu cuenta de usuario",
    msgUserPassText: "Modifica la contraseña asociada a tu cuenta de usuario",
    msgEntityDataText: "Modifica los datos asociados a tu cuenta de empresa",
    msgPaymentDataText: "Modifica los datos asociados a tu método de pago",
    msgSubscriptionText: "Modifica los datos asociados a tu suscripción",



    msgWantOut: "¿Quieres salir?",
    msgEmptyData: "No hay datos disponibles",


    actionCreateAccount: "Crear una cuenta",
    actionLogin: "Iniciar sesión",
    actionLogout: "Cerrar sesión",
    actionAddUser: "Añadir usuario",
    actionUpdateUser: "Actualizar usuario",
    actionUpdateBusi: "Actualizar organización",
    actionUpdatePay: "Actualizar método de pago",
    actionUpdateSub: "Actualizar suscripción",
    actionUpdatePas: "Actualizar contraseña",

    actionAddNote: "Añadir nota",
    actionUpdateNote: "Actualizar nota",
    actionSend: "Enviar",
    actionNew: "Añadir",
    actionDelete: "Eliminar",
    actionAssign: "Asignar",
    actionDesa: "Desasignar",
    actionYes: "Si",
    actionNo: "No",
    actionSearch: "Buscar",
    actionClean: "Limpiar",

    errorOblData: "Complete los campos obligatorios",
    errorEmailIncorrect: "El email introducido no existe",
    errorEmailFormat: "Formato de email incorrecto, introduzca uno válido",
    errorLogin: "Email/contraseña incorrectos",
    errorSearchUser: "Usuario no encontrado",
    errorCreateAccount: "Se produjo un error al crear su cuenta, intentelo más tarde",
    errorProcessPayment: "Se produjo un error al procesar su pago, por favor contacte con soporte",
    errorUserAdmin: "Se produjo un error al crear su usuario, por favor contacte con soporte",
    errorDeleteUser: "No es posible realizar la operación sobre el usuario seleccionado",
    errorUpdate: "Se produjo un error al realizar la actualización del usuario",
    errorOptionFile: "Envie un archivo o un enlace",
    errorSend: "Se produjo un error al realizar el envio",

    successSend: "Envio realizado correctamente",
    successUpdate: "Datos actualizados correctamente",
    successAccount: "Cuenta creada con éxito, no cierre la página le redirigiremos al login",

};

export default es;
