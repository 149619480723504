import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { OrderDefault } from '../../../constants/model';
import { UserAction, UserContext } from '../../providers/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../../util/HttpConnection';
import { format } from 'date-fns'
import { mdiInformation, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { LanguageContext } from '../../providers/LanguageProvider';
import { use } from 'react';

export const OrderItemView = ({ item, show, deleteItem }) => {

    const textContext = useContext(LanguageContext);
    const _getState = (stateId) => stateId === 1 ? textContext.dictionary["msgNotAssign"] : stateId === 2 ? textContext.dictionary["msgAssign"] : stateId === 3 ? textContext.dictionary["msgAccepted"] : stateId === 4 ? textContext.dictionary["msgRejected"] : textContext.dictionary["msgEnded"];

    return <ContainerFlex align="flex-start" ph="32" pv="10">
        <Container width="wrap">
            <Container fontWeight="500" fontSize="20px" children={`${item.identificador}`} />
            <Container fontWeight="500" fontSize="10px" children={`${_getState(item.idEstadoParte)}`} />

            <ContainerFlex width="12" fontSize="14px" fontColor="color-gray" pv="4" >
                <Container width="6" children={`Org: ${item.origen}`} />
                <Container width="6" children={`Dest: ${item.destino}`} />
            </ContainerFlex>

            <ContainerFlex width="12" fontSize="14px" color="color-primary-light" fontColor="color-black" pv="4" >
                <Container width="6" children={`Ini: ${format(new Date(item.fechaInicio), "dd/MM/yyyy HH:mm")}`} />
                <Container width="6" children={`Fin: ${format(new Date(item.fechaFin), "dd/MM/yyyy HH:mm")}`} />
            </ContainerFlex>
        </Container>
        <Button onClick={() => show(item)} px="10" color="color-primary" hoverColor="color-primary-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiInformation} size="20px" />} />
        <Button onClick={() => deleteItem(item)} px="10" ml="10" color="color-red" hoverColor="color-red-dark" fontColor="color-white" height="40px" radius="50%" children={<Icon path={mdiTrashCanOutline} size="20px" />} />
    </ContainerFlex>
}

const OrderListView = () => {

    const [items, setItems] = useState([]);
    const [drivers, setDrivers] = useState([])

    const { dispatch } = useContext(UserContext);
    const [filter, setFilter] = useState({ state: 1, driverId: "", search: "" });

    const history = useNavigate();
    const languageContext = useContext(LanguageContext);

    useEffect(() => {
        _refreshData();

        HttpConnection(HttpOperations.users,
            result => setDrivers(result),
            error => console.log(error), HttpMethod.get)
    }, [])

    const _refreshData = () => {

        let columns = "columns=";
        let values = "values=";

        if (filter.search !== "") {
            columns += "identificador";
            values += filter.search;
        }

        if (filter.driverId !== "") {
            columns += columns !== "columns=" ? ",idUser" : "idUser";
            values += values !== "values=" ? `,${filter.driverId}` : `${filter.driverId}`;
        }

        HttpConnection(`${HttpOperations.order}/page/company?page=0&count=500&${columns}&${values}`,
            result => setItems(result.reverse()),
            error => console.log(error), HttpMethod.get)
    }

    const deleteItem = (item) => {
        HttpConnection(`${HttpOperations.order}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "successUpdate" } });
                _refreshData();
            },
            error => console.log(error), HttpMethod.delete)
    }

    const showItem = (item = { ...OrderDefault }) => history("/order", { state: { item: item } });

    const cleanSearch = () => {
        setFilter({ ...filter, search: "", driverId: "" });
        _refreshData();
    }

    return <Container width="12" minheight="calc(100vh - 255px)" >
        <ContainerFlex width="12" textAlign="center" fontWeight="bold" fontSize="48px" align="center" justify="center">
            <Container pv="25" textAlign="center" children={<TextLocalized children="msgOrders" />} />
        </ContainerFlex>

        <ContainerFlex width="12" px="12" justify="center">
            <Card shadowOn width="12" lg="8">
                <ContainerFlex width="12" pv="20" ph="32" align="flex-start">
                    <ContainerFlex width="12" justify="space-between" align="center">
                        <Container width={12} textAlign="left">
                            <Container width="12" fontSize="1.5rem" fontWeight="bold" pb="4" children={<TextLocalized children="msgOrders" />} />
                            <Container width="12" fontColor="color-gray" children={<TextLocalized children="msgOrdersListText" />} />
                        </Container>
                        <Button display="none" onClick={() => showItem()} mh="4" pv="8" ph="16" color={"color-primary"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionNew" />} />
                    </ContainerFlex>

                    <ContainerFlex width="12" pt="10">
                        <Card border="solid" borderColor="color-primary" borderWidth="1px" width="wrap" children={<input value={filter.search} onChange={e => setFilter({ ...filter, "search": e.target.value })} placeholder={languageContext.dictionary["msgIdentifier"]} style={{ padding: "7px" }} />} />

                        <Container width="4" pl="8">
                            <Card width="12" shadowOn={false} border="solid" borderColor="color-primary" borderWidth="1px">
                                <select value={filter.driverId ?? ""} onChange={(e) => setFilter({ ...filter, driverId: e.target.value })} style={{ padding: "7px", height: "37px", width: "100%" }}>
                                    <option value="" children="Seleccionar conductor" />
                                    {drivers.map(item => <option value={item.id} key={item.id} children={`${item.fullname} - ${item.username} - ${item.id}`} />)}
                                </select>
                            </Card>
                        </Container>

                        <ContainerFlex width="wrap">
                            <Button onClick={_refreshData} ml="8" mr="4" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionSearch" />} />
                            <Button onClick={cleanSearch} mh="4" pv="8" ph="16" color="color-primary" hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="actionClean" />} />
                        </ContainerFlex>
                    </ContainerFlex>

                    <ContainerFlex width="12" mt="16">
                        <Button onClick={() => setFilter({ ...filter, state: 1 })} mh="4" pv="8" ph="16" color={filter.state === 1 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgNotAssign" />} />
                        <Button onClick={() => setFilter({ ...filter, state: 2 })} mh="4" pv="8" ph="16" color={filter.state === 2 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgAssign" />} />
                        <Button onClick={() => setFilter({ ...filter, state: 3 })} mh="5" pv="8" ph="16" color={filter.state === 3 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgAccepted" />} />
                        <Button onClick={() => setFilter({ ...filter, state: 4 })} mh="4" pv="8" ph="16" color={filter.state === 4 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgRejected" />} />
                        <Button onClick={() => setFilter({ ...filter, state: 5 })} mh="4" pv="8" ph="16" color={filter.state === 5 ? "color-primary" : "color-primary-light"} hoverColor="color-primary-dark" textAlign="center" fontWeight="bold" fontColor="color-white" children={<TextLocalized children="msgEnded" />} />
                    </ContainerFlex>
                </ContainerFlex>
                <Container width="12" color="color-gray-dark" height="1px" />
                <Container width="12" pv="10" maxheight="425px" overflowY="auto" children={items.filter(item => item.idEstadoParte === filter.state || filter.state === 0).map((item, index) => <OrderItemView key={index} item={item} show={showItem} deleteItem={deleteItem} />)} />
            </Card>
        </ContainerFlex>

    </Container>
};

export default OrderListView;